import styled from 'styled-components'

export default styled.div`
  .recharts-default-legend {
    text-align: initial !important;
  }
  .graph-legend-container {
    display: flex;
    flex-wrap: wrap;
    .dropdown-legend {
      width: 175px;
    }
    .data-legend-container {
      display: flex;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;
      &.strike-through {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 0px;
          border-top: 2px solid rgba(31, 45, 57, 0.5);
          width: 100%;
        }
      }
      .line-color {
        width: 10px;
        height: 2px;
        align-self: center;
        margin-right: 10px;
      }
      .circle-color {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
      .data-legend-value {
        color: rgba(31, 45, 57, 0.5);
        font-family: 'Prompt-Medium', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        max-width: 100px;
      }
    }
  }
  .rechart-tooltip-container {
    padding: 12px;
    border-radius: 5px;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    background: #eef7ff;
    color: #1f2d39;
    .label-container {
      margin-bottom: 6px;
      font-weight: bold;
    }
    .data-key-container {
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      .circle-color {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        margin-right: 10px;
      }
      .data-value-container {
        font-family: 'Prompt', sans-serif;
        font-size: 10px;
        .data-key {
          margin-right: 4px;
        }
        .data-value {
          font-style: italic;
        }
      }
    }
  }
`
