const MESSAGE = {
  EN: {
    violation_option: 'Violation',
    no_of_ppl_option: 'Number of People'
  },
  TH: {
    violation_option: 'จำนวนการละเมิด',
    no_of_ppl_option: 'จำนวนคน'
  }
}

export default MESSAGE
