import React from 'react'
import PropTypes from 'prop-types'
import { Bar, ComposedChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label, Text } from 'recharts'
import Dropdown from '../Dropdown/Loadable'
import BarChartComponentStyled from './styledComponent'
import MESSAGE from './message'

import colors from '../../colors'

import { NORMAL_ACTIVITY, VIOLATION, NUMBER_OF_PEOPLE } from '../../utils'

const barStyled = {
  fontSize: 10,
  fontFamily: 'Prompt, sans-serif'
}

class BarChartComponent extends React.PureComponent {
  state = {
    listHideDataKey: [],
    activeLegend: { value: VIOLATION, color: colors.orangeFont }
  }

  componentDidMount() {
    if (this.props.dropdownForGraph) {
      this.setState({
        listHideDataKey: [...this.state.listHideDataKey, NUMBER_OF_PEOPLE]
      })
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  generateBar() {
    const listBar = this.props.listBars.filter((data) => data.name !== NORMAL_ACTIVITY)
    const graphs = listBar.map((graphData) => {
      return <Bar key={graphData.name} dataKey={graphData.name} fill={graphData.color} barSize={10} minPointSize={0.5} />
    })
    return graphs
  }

  preparedListDataKey() {
    const preparedList = this.props.data.map((data) => {
      const temp = Object.assign({}, data)
      let listNecessaryDataKey = Object.keys(temp).filter((key) => !this.state.listHideDataKey.includes(key))

      const pointData = listNecessaryDataKey.reduce((obj, key) => {
        obj[key] = temp[key]
        return obj
      }, {})
      return pointData
    })
    return preparedList
  }

  getData() {
    const filterListData = this.preparedListDataKey()
    return filterListData
  }

  renderTooltip({ active, payload, label }) {
    let listDataTooltip = []
    if (payload) {
      listDataTooltip = payload.map((data) => {
        return (
          <div className="data-key-container" key={`${data.dataKey}${data.value}`}>
            <div className="circle-color" style={{ background: `${data.color}` }} />
            <div className="data-value-container">
              <span className="data-key">{data.dataKey}:</span>
              <span className="data-value">{data.value}</span>
            </div>
          </div>
        )
      })
    }
    return (
      <div className="rechart-tooltip-container">
        <div className="label-container">{label}</div>
        {listDataTooltip}
      </div>
    )
  }

  handleLegendClicked(legendData) {
    const { dataKey } = legendData
    const shouldHideDataKey = this.state.listHideDataKey.indexOf(dataKey) === -1
    if (shouldHideDataKey) {
      this.setState({
        listHideDataKey: [...this.state.listHideDataKey, dataKey]
      })
    } else {
      this.setState({
        listHideDataKey: this.state.listHideDataKey.filter((key) => key !== dataKey)
      })
    }

    if (this.props.dropdownForGraph) {
      if (this.state.activeLegend.value !== dataKey && !shouldHideDataKey) {
        let tempListHideDataKey = this.state.listHideDataKey.filter((key) => key !== dataKey)
        tempListHideDataKey.push(this.state.activeLegend.value)
        this.setState({
          listHideDataKey: tempListHideDataKey
        })
      }
      const tempLegend = {
        dataKey: legendData.dataKey,
        text: legendData.dataKey === VIOLATION ? this.getMessage('violation_option') : this.getMessage('no_of_ppl_option'),
        value: legendData.value,
        color: legendData.color
      }
      this.setState({
        activeLegend: tempLegend
      })
    }
  }

  sortNormalAndViolation(arr) {
    const compare = (a, b) => {
      if (a.dataKey < b.dataKey) {
        return 1
      }
      if (a.dataKey > b.dataKey) {
        return -1
      }
      return 0
    }
    let tempArr = arr.sort(compare)
    return tempArr
  }

  renderLegend({ payload }) {
    let tempPayload = []
    if (payload[0].dataKey === NORMAL_ACTIVITY) {
      tempPayload = this.sortNormalAndViolation(payload)
    } else {
      tempPayload = payload
    }
    let listLegend = []
    if (payload[0].dataKey === NUMBER_OF_PEOPLE || payload[0].dataKey === VIOLATION) {
      const legendOptions = tempPayload.map((data) => {
        return {
          dataKey: data.dataKey,
          text: data.dataKey === VIOLATION ? this.getMessage('violation_option') : this.getMessage('no_of_ppl_option'),
          value: data.value,
          color: data.color
        }
      })
      listLegend = (
        <Dropdown
          className="dropdown-legend"
          options={legendOptions}
          onChange={(legend) => this.handleLegendClicked(legend, legendOptions)}
          value={this.state.activeLegend}
          isForGraph
          labelPosition="left"
          buttonColor={colors.darkBackground}
        />
      )
    } else {
      listLegend = tempPayload.map((data) => {
        const isHideDataKey = this.state.listHideDataKey.indexOf(data.dataKey) !== -1
        const classNameLegend = isHideDataKey ? 'data-legend-container strike-through' : 'data-legend-container'
        const isNormalActivity = data.dataKey === NORMAL_ACTIVITY
        const legendDataKeyClassName = isNormalActivity ? 'line-color' : 'circle-color'
        return (
          <div key={data.value} className={classNameLegend} onClick={() => this.handleLegendClicked(data)}>
            <div className={legendDataKeyClassName} style={{ background: `${data.color}`, fontSize: 12 }} />
            <div className="data-legend-value prevent-text-overflow">{data.value}</div>
          </div>
        )
      })
    }

    return <div className="graph-legend-container">{listLegend}</div>
  }

  render() {
    return (
      <BarChartComponentStyled colors={colors}>
        <ResponsiveContainer width="100%" minHeight={255}>
          <ComposedChart data={this.getData()} barGap={0} margin={{ top: 20, bottom: 30, left: 20, right: 15 }}>
            <CartesianGrid strokeWidth="1" vertical={false} style={{ opacity: 0.3 }} />
            <XAxis dataKey="label" tick={barStyled} axisLine={false} tickLine={false}>
              <Label
                value={this.props.xLabel}
                offset={0}
                position="bottom"
                style={Object.assign({}, barStyled, {
                  fontFamily: 'Prompt-Italic, sans-serif',
                  fontSize: 10,
                  color: '#4a4a4a'
                })}
              />
            </XAxis>
            <YAxis
              axisLine={false}
              tick={barStyled}
              tickLine={false}
              label={
                <Text
                  x={-15}
                  y={0}
                  dx={50}
                  dy={150}
                  offset={0}
                  angle={-90}
                  style={Object.assign({}, barStyled, {
                    fontFamily: 'Prompt-Italic, sans-serif',
                    fontSize: 10,
                    color: '#4a4a4a'
                  })}
                >
                  {this.props.yLabel}
                </Text>
              }
            ></YAxis>
            <Tooltip cursor={false} wrapperStyle={Object.assign({}, barStyled, { fontSize: 10 })} content={(data) => this.renderTooltip(data)} />
            <Legend
              iconSize={10}
              iconType="circle"
              verticalAlign="top"
              height={50}
              wrapperStyle={Object.assign({}, barStyled, { fontSize: 18 })}
              content={(data) => this.renderLegend(data)}
            />
            {this.generateBar()}
          </ComposedChart>
        </ResponsiveContainer>
      </BarChartComponentStyled>
    )
  }
}

BarChartComponent.defaultProps = {
  language: 'EN'
}

BarChartComponent.propTypes = {
  listBars: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  yLabel: PropTypes.string,
  xLabel: PropTypes.string,
  dropdownForGraph: PropTypes.bool,
  language: PropTypes.string
}

export default BarChartComponent
